



















































































































































































































































































































import { promiseHelper } from '@/helper/promise-helper'
import { CommentModel } from '@/models/post-model'
import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { postController } from '@/modules/common/dialogs/post/post-controller'
import { PostStore } from '@/stores/post-store'
import { walletStore } from '@/stores/wallet-store'
import { debounce } from 'lodash'
import { runInAction } from 'mobx'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import Avatar from './avatar.vue'

@Observer
@Component({
  components: {
    'post-list-item-content': () => import('./post-list-item-content.vue'),
    Avatar,
    'follow-button': () => import('@/modules/common/components/follow-button.vue'),
    'repost-card-in-dialog': () => import('@/modules/common/components/posts/repost-card-in-dialog.vue'),
    'tag-category': () => import('@/components/tag-category.vue'),
    PostComment: () => import('./post-comment.vue'),
  },
})
export default class PostListItem extends Vue {
  @Prop({}) postStore!: PostStore
  @Prop({ default: false }) enableBlind!: boolean
  @Prop({}) focusedComment?: string

  walletStore = walletStore
  createPostController = createPostController
  postController = postController

  redirectTag(tagContent: string) {
    const searchTerm = tagContent.replaceAll('#', '')
    if (this.$router.currentRoute.path === '/search/forum') {
      this.$router.replace(`/search/forum?text=${searchTerm}`)
    } else {
      this.$router.push(`/search/forum?text=${searchTerm}`)
    }
    this.$forceUpdate()
  }

  async follow() {
    await this.postStore.followUser(this.postStore.post)
    this.$emit('syncFollowState')
  }

  async unFollow() {
    await this.postStore.unFollowUser(this.postStore.post)
    this.$emit('syncFollowState')
  }

  async requestReplyTo(comment: CommentModel) {
    comment.replying = true
    await promiseHelper.delay(200)
    // console.log(`postComment-${comment.id}`, !!this.$refs[`postComment-${comment.id}`], this.$refs);
    ;(this.$refs[`postComment-${comment.id}`] as any)[0].requestFocus(comment.owner)
  }

  async requestReply() {
    this.postStore.changeEnableComment()
    await promiseHelper.delay(200)
    // if (this.postStore.isEnableComment) {
    const arr = this.$refs[`postComment-${this.postStore.post.id}`] as any
    arr?.requestFocus()
    // }
  }

  @Watch('focusedComment', { immediate: true })
  onCommentFocusing(value) {
    if (value) {
      let flag = false
      const interval = setInterval(
        debounce(() => {
          const element = document.getElementById(`comment-${value}`)
          if (element) {
            document.getElementById(`comment-${value}`)?.scrollIntoView({ block: 'center', inline: 'center' })
          }
          flag = true
          if (flag) clearInterval(interval)
        }, 200),
        300
      )
    }
  }
}
